/* eslint-disable */
import { ActionTypes } from "./Actions";

const initialData = {
    ModalPopup: {
        title: "",
        description:"",
        data: {},
        show: "",
        callBackModal: () => null,
        buttonSuccess: "",
        buttonCancel:""
    },
    device_id:"",
    currentUser:null,
    access_token:"",
    remember_me:{},
    categoryList: [],
    categoryListOption: [],
    magic_code:"",
    websocketReqRes: {
        request:null,
        msg:"",
        status:"",
        url:""
    },
    ModalPopup: {
        title: "",
        name:"",
        description:"",
        data: {},
        show: "",
        callBackModal: () => null,
        buttonSuccess: "",
        buttonCancel:""
    },
    coursedList:{
        data:[],
        pagination:{}
    },
    courseDetails:null,
    reviewList:{
        data:[],
        pagination:{}
    },
    testimonialList:{
        data:[],
        pagination:{}
    },
    coursesLevelList:[],
    packageList:[],
    usersDetails: null,
    faqList:[],
    blogList:{
        data:[],
        pagination:{},
    },
    salesHistory:{
        data:[],
        pagination:{},
    },
    privacyPolicyList:[],
    termsAndConditionsList:[],
    dashboardStat:{
        individual_users_count: "",
        business_register_count: "",
        total_users_count: "",
        total_courses_count: "",
    },
    heightAssignCourse:[],
    catalogueCourseList:{
        data:[],
        pagination:{},
    },
    
};
const jsonParse = (data) =>{
    if(data === undefined || data === "")
        return null;
    if(typeof(data) === "string") {
        return data?.replaceAll(/None/g, 'null').replaceAll(/False/g, false).replaceAll(/True/g, true)?.replaceAll(/'/g, '"').replace(/\\"/g, '"');
    } 
    return null;
}

const decodeBase64 = (base64String) => {
    try {
        const decodedJsonString = atob(base64String);

        const jsonObject = JSON.parse(decodedJsonString);
        
        if (typeof jsonObject.course_data === "string") {
            jsonObject.course_data = JSON.parse(jsonObject.course_data);
        }
        return jsonObject
    } catch (error) {
        console.error("Base64 Decode Error:", error, "Data:", base64String);
        return null;
    }
};
// const jsonParse = (data) => {
//     JSON.stringify(data)
//     if (!data) return null;  // Return null if input data is not provided

//     // Replace necessary values for valid JSON parsing
//     let parsedData = data
//         .replaceAll("None", "null")         // Replace None with null
//         .replaceAll("False", "false")       // Replace False with false
//         .replaceAll("True", "true")         // Replace True with true
//         .replaceAll(/'/g, '"');             // Replace single quotes with double quotes

//     try {
//         return JSON.parse(parsedData);  // Try to parse the JSON string
//     } catch (error) {
//         console.error("Invalid JSON format", error);
//         return null;  // Return null if parsing fails
//     }
// }


export const allReducers = (state = initialData, action) => {
    switch (action.type) {
        case ActionTypes.SET_STORE_CURRENT_USER:
            if(!action?.payload){
                return {
                    ...initialData,
                    remember_me: state?.remember_me,
                };
            }
            return{
                ...state,
                currentUser: action.payload,
                access_token: action?.payload?.access_token,
            }
        case ActionTypes.SET_DEVICE_ID:{
            return{
                ...state,
                device_id: action.payload,
            }
        }
        case ActionTypes.SET_STORE_REVIEW_LIST:{
            return{
                ...state,
                reviewList: action.payload,
            }
        }
        case ActionTypes.SET_STORE_REVIEW_UPDATE_LIST:{
            const newData = state?.reviewList?.data?.map((item)=>{
                if(item?.id == action?.payload?.id){
                    return {
                        ...item,
                        ...action?.payload
                    };
                }
                return{
                    ...item,
                }
            })
            return{
                ...state,
                reviewList:{
                    ...state?.reviewList,
                    data: newData
                },
            }
        }
        case ActionTypes.SET_STORE_UPDATE_TESTIMONIAL_LIST:{
            const newData = state?.testimonialList?.data?.map((item)=>{
                if(item?.id == action?.payload?.id){
                    return {
                        ...item,
                        ...action?.payload
                    };
                }
                return{
                    ...item,
                }
            })
            return{
                ...state,
                testimonialList:{
                    ...state?.testimonialList,
                    data: newData
                },
            }
        }
        case ActionTypes.SET_STORE_TESTIMONIAL_LIST:{
            const payloadData = action.payload?.data?.map((item)=>{
                const Data = jsonParse(item?.image)
                return{
                    ...item,
                    label: item?.category_name,
                    value: item?.id,
                    image: item?.icon === "None" ? null : JSON.parse(Data)
                }
            })
            return{
                ...state,
                testimonialList: {
                    data: payloadData,
                    pagination: action?.payload?.pagination,
                },
            }
        }
        case ActionTypes.SET_STORE_CATEGORY_LIST:{
            const payloadData = action.payload?.map((item)=>{
                const Data = item?.icon.replaceAll("None", 'null').replaceAll("False", false).replaceAll("True", true)?.replaceAll(/'/g, '"');
                return{
                    ...item,
                    label: item?.category_name,
                    value: item?.id,
                    icon: item?.icon === "None" ? null : JSON.parse(Data)
                }
            })
            return{
                ...state,
                categoryList: payloadData,
                categoryListOption: payloadData
            }
        }
        case ActionTypes.SET_STORE_MAGIC_CODE:{
            return{
                ...state,
                magic_code:action?.payload,
            }
        }
        case ActionTypes.SET_WEBSOCKET_REQ_RES:{
            return {
                ...state,
                websocketReqRes:{
                    request:action?.payload?.request || null,
                    msg:action?.payload?.msg || "",
                    status:action?.payload?.status || "",
                    url:action?.payload?.url || "",
                }
            }
        }
        case ActionTypes.SET_STORE_MODAL_CONFIRM:{
            // console.log("action?.payload",action?.payload)
            return {
                ...state,
                ModalPopup: action?.payload?.show ? action?.payload : initialData?.ModalPopup
            }
        }
        case ActionTypes.SET_STORE_MODAL_POPUP:
            return {
                ...state,
                ModalPopup: action?.payload?.show ? action?.payload : initialData?.ModalPopup
            }
        case ActionTypes.SET_STORE_COURSES_LIST:{
            const payloadData = action.payload?.data?.filter((item)=>item?.id)?.map((item)=>{
                    const course_image = jsonParse(item?.course_image);
                    const external_pdf = jsonParse(item?.external_pdf);
                    const course_video = jsonParse(item?.course_video);
                    const course_pdf = jsonParse(item?.course_pdf);
                    return{
                        ...item,
                        label: item?.course_name,
                        value: item?.id,
                        course_image: item?.course_image == null|| !item?.course_image || item?.course_image === "None" ? null : JSON.parse(course_image),
                        external_pdf: item?.external_pdf == null|| !item?.external_pdf || item?.external_pdf === "None" ? null : JSON.parse(external_pdf),
                        course_video: item?.course_video == null|| !item?.course_video || item?.course_video === "None" ? null : JSON.parse(course_video),
                        course_pdf: item?.course_pdf == null|| !item?.course_pdf || item?.course_pdf === "None" ? null : JSON.parse(course_pdf),
                    }
            });
            // console.log("payloadData", payloadData, state?.coursedList, action.payload?.data)
            return{
                ...state,
                coursedList: {
                    data: payloadData,
                    pagination: action.payload?.pagination
                },
            }
        }
        case ActionTypes.SET_STORE_COURSE_UPDATE_LIST:{
            // console.log("action.payload", action.payload);
            const newData = state?.coursedList?.data?.map((item)=>{
                const checkData = action.payload?.course_ids?.find(item1=>item1 == item?.id);
                if(checkData){
                    if(action?.payload?.star!=undefined){
                        item.is_star = action?.payload?.star;
                    }
                    if(action?.payload?.status!=undefined){
                        item.is_active = action?.payload?.status;
                    }
                }
                return{
                    ...item,
                }
            })
            return{
                ...state,
                coursedList:{
                    ...state?.coursedList,
                    data: newData
                },
            }
        }
        case ActionTypes.SET_STORE_COURSE_DETAILS:{
            const courseDetails = action?.payload;
            console.log("courseDetails", courseDetails);
            if(action?.payload){
                    const course_image = jsonParse(courseDetails?.course_image);
                    const external_pdf = jsonParse(courseDetails?.external_pdf);
                    const course_video = jsonParse(courseDetails?.course_video);
                    const course_pdf = jsonParse(courseDetails?.course_pdf);
                    if(course_image == null|| !course_image || course_image === "None"){
                        courseDetails.course_image = null;
                    }else{
                        courseDetails.course_image = JSON.parse(course_image);
                    }
                    if(external_pdf == null|| !external_pdf || external_pdf === "None"){
                        courseDetails.external_pdf = null;
                    }else{
                        courseDetails.external_pdf = JSON.parse(external_pdf);
                    }
                    if(course_video == null|| !course_video || course_video === "None"){
                        courseDetails.course_video = null;
                    }else{
                        courseDetails.course_video = JSON.parse(course_video);
                    }
                    if(course_pdf == null|| !course_pdf || course_pdf === "None"){
                        courseDetails.course_pdf = null;
                    }else{
                        courseDetails.course_pdf = JSON.parse(course_pdf);
                    }
                    if(action?.payload?.modules?.length>0){
                        const models = action?.payload?.modules?.filter((item)=>item?.id)?.map((item)=>{
                            const file = jsonParse(item?.file);
                            const image = jsonParse(item?.image);
                            const videos = item?.videos?.map((item)=>{
                                const course_image = JSON.parse(jsonParse(item?.video));
                                return{
                                    ...course_image,
                                    video_id: item?.id,
                                }
                            })
                            const updatedItem = {
                                ...item,
                                unit: item?.unit?.map((unit) => ({
                                  ...unit,
                                  chapter: unit?.chapter?.map((chapter) => {
                                    const decodedDetails = decodeBase64(chapter?.course_data?.details);
                                    // console.log("chapter===", chapter, decodedDetails)
                              
                                    return {
                                      ...chapter,
                                      course_data: {...chapter?.course_data, details:decodedDetails}, // Decode and parse JSON from Base64
                                      chapter_name: chapter?.course_data?.chapter_name || "", // Extract chapter_name from details
                                      unit_name: unit?.unit_name || "",
                                    };
                                  }) || [],
                                })) || [],
                              };
                              
                            // console.log("updatedItem", updatedItem)
                            return{
                                ...updatedItem,
                                file: item?.file == null|| !item?.file || item?.file === "None" ? null : JSON.parse(file),
                                image: item?.image == null|| !item?.image || item?.image === "None" ? null : JSON.parse(image),
                                videos: videos
                            }
                        });
                        courseDetails.models = models
                        courseDetails.modules = models;
                    }else{
                        courseDetails.models = []
                        courseDetails.modules = [];
                    }
            }
            return{
                ...state,
                courseDetails: courseDetails
            }
        }
        case ActionTypes.SET_STORE_MODULE_ADD_UPDATE:{
            const updatedModules = state?.courseDetails?.modules ? [...state?.courseDetails?.modules] : [];

            const moduleIndex = updatedModules?.findIndex(module => module?.id === action?.payload?.id);
        
            if (moduleIndex !== -1) {
                // Update existing module
                updatedModules[moduleIndex] = {
                    ...updatedModules[moduleIndex],
                    ...action?.payload,
                };
            } else {
                // Add new module
                updatedModules.push(action?.payload);
            }
        
            return {
                ...state,
                courseDetails: {
                    ...state?.courseDetails,
                    modules: updatedModules
                }
            };
        }
        case ActionTypes.SET_STORE_UNIT_CHAPTER_ADD_UPDATE: {
            // console.log("Unit Add/Update", action?.payload);
        
            const updatedModules = state?.courseDetails?.modules ? [...state?.courseDetails?.modules] : [];
        
            // Find the module by module_id
            const moduleIndex = updatedModules.findIndex(module => module?.id === action?.payload?.request2?.module_id);
        
            if (moduleIndex !== -1) {
                const updatedModule = { ...updatedModules[moduleIndex] };
            
                // Ensure unit is an array
                updatedModule.unit = updatedModule.unit || [];
            
                // console.log("Updated Module Before Insert:", updatedModule);
            
                // Remove existing unit with the same ID
                updatedModule.unit = updatedModule.unit.filter(unit => unit.id !== action?.payload?.request?.unit_id);
            
                // Create new unit object
                const newUnit = {
                    ...action?.payload?.request2,
                    id: action?.payload?.request?.unit_id,
                };
            
                // Determine valid index
                const insertIndex = action?.payload?.unitIndex ?? updatedModule.unit.length;
                const validInsertIndex = Math.min(Math.max(insertIndex, 0), updatedModule.unit.length);
            
                // Insert the new unit
                updatedModule.unit.splice(validInsertIndex, 0, newUnit);
            
                // Update the module
                updatedModules[moduleIndex] = updatedModule;
            }
        
            return {
                ...state,
                courseDetails: {
                    ...state?.courseDetails,
                    modules: updatedModules
                }
            };
        }
        
        
        case ActionTypes.SET_STORE_UNIT_CHAPTER_DELETE: {
            console.log("Initial courseDetails.modules:", state?.courseDetails?.modules);
        
            const updatedModules = state?.courseDetails?.modules ? [...state?.courseDetails?.modules] : [];
        
            const moduleIndex = updatedModules.findIndex(module => module?.id === action?.payload?.request2?.module_id);
            console.log("action?.payload",action?.payload)
            console.log("Found module index:", moduleIndex);
        
            if (moduleIndex !== -1) {
                // Clone the module
                let updatedModule = { ...updatedModules[moduleIndex] };
        
                console.log("Before deletion - Module:", updatedModule);
        
                // Remove the unit if `unit_id` is provided
                if (action?.payload?.request?.unit_id) {
                    updatedModule.unit = updatedModule?.unit?.filter(unit => unit.id !== action?.payload?.request?.unit_id);
                    console.log("After unit removal:", updatedModule.unit);
                }
        
                // If `chapter_id` is provided, remove the chapter inside the unit
                if (action?.payload?.request?.chapter_id) {
                    updatedModule.unit = updatedModule?.unit?.map(unit => {
                        console.log("Processing unit:", unit.id);
        
                        return {
                            ...unit,
                            chapters: unit?.chapters?.filter(chapter => chapter.id !== action?.payload?.request?.chapter_id) || []
                        };
                    });
        
                    console.log("After chapter removal:", updatedModule.unit);
                }
        
                // Update the module in the array
                updatedModules[moduleIndex] = updatedModule;
        
                console.log("Updated module:", updatedModule);
            } else {
                console.warn("Module not found with ID:", action?.payload?.request2?.module_id);
            }
        
            console.log("Final updatedModules:", updatedModules);
        
            return {
                ...state,
                courseDetails: {
                    ...state?.courseDetails,
                    modules: updatedModules
                }
            };
        }
        
        
        
        case ActionTypes.SET_STORE_COURSES_LEVEL_LIST:{
            const payloadData = action.payload?.map((item)=>{
                return{
                    ...item,
                    label: item?.level,
                    value: item?.id,
                }
            })
            return{
                ...state,
                coursesLevelList:payloadData,
            }
        }
        case ActionTypes.SET_STORE_PACKAGE_LIST:{
            const payloadData = action.payload?.map((item)=>{
                return{
                    ...item,
                    // label: item?.level,
                    value: item?.id,
                }
            })
            return{
                ...state,
                packageList:payloadData,
            }
        }
        case ActionTypes.SET_STORE_USER_MANAGER_LIST:{
            const payloadData = action.payload?.data?.filter((item)=>item?.id)
            return{
                ...state,
                userManager: {
                    data: payloadData,
                    pagination: action.payload?.pagination
                },
            }
        }
        case ActionTypes.SET_STORE_LOGIN_FORM:{
            return{
                ...state,
                remember_me: action?.payload,
            }
        }
        case ActionTypes.SET_STORE_USERS_DETAILS:{
            return{
                ...state,
                usersDetails: action?.payload,
            }
        }
        case ActionTypes.SET_STORE_FAQ_LIST:{
            // console.log("FAQLIST", action?.payload);
            return{
                ...state,
                faqList:action?.payload
            }
        }
        case ActionTypes.SET_STORE_BLOG_LIST:{
            const payloadData = action.payload?.data?.filter((item)=>item?.id)?.map((item)=>{
                const image = jsonParse(item?.image);
                return{
                    ...item,
                    image: item?.image == null|| !item?.image || item?.image === "None" ? null : JSON.parse(image),
                }
        });
            return{
                ...state,
                blogList:{
                    data:payloadData,
                    pagination:action?.payload?.pagination,
                }
            }
        }
        case ActionTypes.SET_STORE_PRIVACY_POLICY_LIST:{
            // console.log("policylist", action?.payload);
            return{
                ...state,
                privacyPolicyList:action?.payload
            }
        }
        case ActionTypes.SET_STORE_TERMS_AND_CONDITIONS_LIST:{
            // console.log("terms", action?.payload);
            return{
                ...state,
                termsAndConditionsList:action?.payload
            }
        }
        case ActionTypes.SET_STORE_SALES_HISTORY_LIST:{
            return{
                ...state,
                salesHistory:action?.payload
            }
        }
        case ActionTypes.SET_STORE_DASHBOARD_COUNT:{
            return{
                ...state,
                dashboardStat:action?.payload ? action?.payload : state?.dashboardStat
            }
        }
        case ActionTypes.SET_STORE_HIGHEST_ASSIGN_COURSE_LIST:{
            return{
                ...state,
                heightAssignCourse:action?.payload ? action?.payload : state?.heightAssignCourse
            }
        }
        case ActionTypes.SET_STORE_CATALOGUE_COURSE_LIST:{
            const payloadData = action?.payload?.data?.filter((item)=>item?.id)?.map((item)=>{
                const course_image = jsonParse(item?.course_image);
                return{
                    ...item,
                    course_image: item?.course_image == null || !item?.course_image || item?.course_image === "None" ? null : JSON.parse(course_image),
                }
            });
            return{
                ...state,
                catalogueCourseList:{
                    data: payloadData,
                    pagination: action?.payload?.pagination,
                }
            }
        }
        default:
            return state;
    }
};